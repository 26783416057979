<template>
  <!-- Version: 1.6 -->
  <h2>1. Περιγραφή παιχνιδιού</h2>
  <p>
    Το παιχνίδι Soccerbet της Highlight Games είναι μια μοναδική προσομοίωση λογισμικού με τυχαία επιλεγμένα στιγμιότυπα
    από ποδοσφαιρικούς αγώνες για τη δημιουργία ευκαιριών παιχνιδιού.
  </p>
  <h2>2. Περιγραφή και διάρκεια παιχνιδιού</h2>
  <p>
    Ένας μεμονωμένος γύρος ενός παιχνιδιού ηλεκτρονικού στοιχηματισμού έχει διάρκεια από 3 έως 5 λεπτά. Αποτελείται από
    την ανακοίνωση του αγώνα, την εβδομάδα παιχνιδιού, το προοίμιο, την απεικόνιση του παιχνιδιού και τα αποτελέσματα. Η
    απεικόνιση του παιχνιδιού θα έχει διάρκεια 1 λεπτό. Μια ολόκληρη σεζόν μπορεί να διαρκέσει από 78 λεπτά έως 190
    λεπτά. Αυτό οφείλεται στον αριθμό των ομάδων στο συγκεκριμένο πρωτάθλημα. Επομένως, ο αριθμός των εβδομάδων
    παιχνιδιού θα ποικίλλει.
  </p>
  <p>
    Μια σειρά από κορυφαία ποδοσφαιρικά στιγμιότυπα είναι στατιστικά ανεξάρτητη, τυχαία και απρόβλεπτη και δημιουργείται
    από τη Γεννήτρια Τυχαίων Αριθμών (Random Number Generator – RNG).
  </p>
  <h2>3. Αγορές στοιχηματισμού</h2>
  <h3>3.1 Ακριβής αριθμός γκολ</h3>
  <p>
    Η έννοια του στοιχήματος για τον ακριβή αριθμό γκολ είναι ότι ένας ακριβής αριθμός γκολ πρέπει να σημειωθεί σε έναν
    αγώνα. Έτσι, αυτή η παραλλαγή στοιχήματος σημαίνει ότι πρέπει να προβλέψετε έναν ακριβή αριθμό γκολ ανεξαρτήτως του
    τελικού αποτελέσματος ενός ολόκληρου αγώνα.
  </p>
  <h3>3.2 Ακριβής αριθμός γκολ για τη γηπεδούχο ομάδα</h3>
  <p>Ένα στοίχημα για τον ακριβή αριθμό γκολ που θα σκοράρει η γηπεδούχος ομάδα στον αγώνα.</p>
  <h3>3.3 Ακριβής αριθμός γκολ για τη φιλοξενούμενη ομάδα</h3>
  <p>Ένα στοίχημα για τον ακριβή αριθμό γκολ που θα σκοράρει η φιλοξενούμενη ομάδα στον αγώνα.</p>
  <h3>3.4 Και οι δύο ομάδες σκοράρουν</h3>
  <p>
    Και οι δύο ομάδες πρέπει να σκοράρουν τουλάχιστον ένα γκολ, κάνοντας οποιοδήποτε αποτέλεσμα από 1-1 και πάνω,
    κερδοφόρο στοίχημα. Το στοίχημα «και οι δύο ομάδες σκοράρουν» δίνει στους παίκτες την ευκαιρία να επωφεληθούν από
    τέτοια γεγονότα, προκειμένου να λάβουν κερδοφόρες αποδόσεις, όταν μόνο μία από τις δύο ομάδες έχει καταφέρει να
    σκοράρει.
  </p>
  <h3>3.5 Σωστό σκορ</h3>
  <p>
    Το στοίχημα με το σωστό σκορ είναι το στοίχημα στο ποιο πιστεύετε ότι θα είναι το τελικό αποτέλεσμα ενός
    ποδοσφαιρικού αγώνα με μέγιστο αριθμό 6 γκολ σε ένα παιχνίδι, με 28 πιθανά αποτελέσματα.
  </p>
  <h3>3.6 Διπλή ευκαιρία</h3>
  <p>
    Ένα στοίχημα διπλής ευκαιρίας σάς επιτρέπει να καλύψετε δύο από τα τρία πιθανά αποτελέσματα σε έναν ποδοσφαιρικό
    αγώνα με ένα στοίχημα. Νίκη της γηπεδούχου ομάδας και ισοπαλία - Το στοίχημά σας είναι νικηφόρο εάν η γηπεδούχος
    ομάδα κερδίσει ή έρθει ισοπαλία. Νίκη φιλοξενούμενης ομάδας και ισοπαλία – Κερδίζετε το στοίχημά εάν η φιλοξενούμενη
    ομάδα κερδίσει ή έρθει ισοπαλία.
  </p>
  <h3>3.7 Αποτέλεσμα αγώνα (1X2)</h3>
  <p>
    Ένα στοίχημα για νίκη της γηπεδούχου ομάδας (1), νίκη της φιλοξενούμενης ομάδας (2) ή το αποτέλεσμα του αγώνα να
    είναι ισοπαλία (X).
  </p>
  <h3>3.8 Αποτέλεσμα αγώνα και σκοράρισμα και από τις δύο ομάδες (1X2 + BTTS)</h3>
  <p>
    Ένα στοίχημα για το αποτέλεσμα του αγώνα (1 είναι νίκη της γηπεδούχου, Χ είναι ισοπαλία, 2 είναι νίκη της
    φιλοξενούμενης) και εάν θα σκοράρουν ή όχι και οι δύο ομάδες.
  </p>
  <ul>
    <li>1 + γκολ – Η γηπεδούχος ομάδα κερδίζει και οι δύο ομάδες σκοράρουν τουλάχιστον ένα γκολ [π.χ. 3-1].</li>
    <li>1+ χωρίς γκολ – Η γηπεδούχος ομάδα κερδίζει και είναι η μοναδική ομάδα που σκοράρει [π.χ. 1-0].</li>
    <li>X + γκολ – Στοίχημα σε ισοπαλία και σκοράρισμα και από τις δύο ομάδες [π.χ., 1-1, 2-2].</li>
    <li>Χ + χωρίς γκολ – Στοίχημα σε ισοπαλία και καμία ομάδα δεν σκοράρει [Μόνο 0-0].</li>
    <li>2 + γκολ – Η φιλοξενούμενη ομάδα κερδίζει και οι δύο ομάδες σκοράρουν τουλάχιστον ένα γκολ [π.χ. 1-2, 1-3].</li>
    <li>2 + χωρίς γκολ – Η φιλοξενούμενη ομάδα κερδίζει και είναι η μοναδική ομάδα που σκοράρει [π.χ., 0-1, 0-2].</li>
  </ul>
  <h3>3.9 Αποτέλεσμα αγώνα και Over/Under 1,5 γκολ</h3>
  <p>
    Ένα αποτέλεσμα για το αποτέλεσμα του αγώνα ως 1X2 και ότι ο συνολικός αριθμός γκολ θα είναι λιγότερα ή περισσότερα
    από 1,5 γκολ.
  </p>
  <h3>3.10 Αποτέλεσμα αγώνα και Over/Under 2,5 γκολ</h3>
  <p>
    Ένα αποτέλεσμα για το αποτέλεσμα του αγώνα ως 1X2 και ότι ο συνολικός αριθμός γκολ θα είναι λιγότερα ή περισσότερα
    από 2,5 γκολ.
  </p>
  <h3>3.11 Συνολικά γκολ (1 έως 2, 1 έως 3, 1 έως 4, 2 έως 3, 2 έως 4)</h3>
  <p>
    Ένα στοίχημα ότι ο αγώνας θα έχει είτε 1 έως 2, 1 έως 3, 1 έως 4, 2 έως 3 ή 2 έως 4 συνολικά γκολ κατά το τελικό
    αποτέλεσμα. Αυτό είναι ένα στοίχημα με επιλογές ΝΑΙ ή ΌΧΙ.
  </p>
  <h3>3.12 Over/Under 1,5 γκολ για τη γηπεδούχο ομάδα</h3>
  <p>Ένα στοίχημα ότι η γηπεδούχος ομάδα θα σκοράρει είτε περισσότερα ή λιγότερα από 1,5 γκολ στον αγώνα.</p>
  <h3>3.13 Over/Under 1,5 γκολ για τη γηπεδούχο ομάδα</h3>
  <p>Ένα στοίχημα ότι η φιλοξενούμενη ομάδα θα σκοράρει είτε περισσότερα ή λιγότερα από 1,5 γκολ στον αγώνα.</p>
  <h3>3.14 Over/Under 1,5, 2,5, 3,5, 4,5 συνολικά γκολ</h3>
  <p>
    Ένα στοίχημα ότι ο συνολικός αριθμός γκολ που θα σκοράρουν και οι δύο ομάδες θα είναι περισσότερα ή λιγότερα από
    1,5, 2,5, 3,5 ή 4,5 γκολ στον αγώνα.
  </p>
</template>
